import { BrowserModule } from '@angular/platform-browser';
import { NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { WorkspaceComponent } from './workspace/workspace.component';
import { Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginFormComponent } from './login/_form/loginform.component';
import { LoginFormFieldComponent } from './login/_form/loginform-field.component';
import { AuthGuardService } from './auth-guard.service';
import { RpcService } from './rpc.service';
import { EqualValidator } from './firsttimeaccess/createAdmin/equal-validator';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { LocaleHelper } from '../locale/localeHelper';
import { AzureService } from './azure.service';
import { RedirectComponent } from './redirecting/redirecting.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { QrCodeModal } from './login/_form/qrCodeModal/qrCodeModal.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalModule, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalAngularConfiguration, MsalInterceptor, BroadcastService } from '@azure/msal-angular';
import { RdpLaunchDialogComponent } from './workspace/rdp-launch-dialog/rdp-launch-dialog.component';
import { Configuration } from 'msal';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

function appInitializerFn(configService: AzureService) {
  return () => configService.loadAppConfig();
};

export function msalConfigFactory(config: AzureService): Configuration {
  const auth = {
    auth: {
      clientId: config.getClientID(),
      authority: config.getAuthority(),
      redirectUri: window.location.origin + '/#/redirecting/',
      postLogoutRedirectUri: window.location.origin + '/',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE,
    }
  };
  return (auth as Configuration);
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent, LoginComponent, WorkspaceComponent, routingComponents,
    LoginFormComponent, LoginFormFieldComponent, EqualValidator, RedirectComponent, QrCodeModal, RdpLaunchDialogComponent
  ],
  imports: [
    BrowserModule, FormsModule, HttpClientModule, AppRoutingModule, BrowserAnimationsModule, ReactiveFormsModule, MatCardModule, MatMenuModule, MatInputModule, MatButtonModule,
    MatProgressBarModule, MatDialogModule, MatProgressSpinnerModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, AuthGuardService, RpcService, I18n,
  {
    provide: TRANSLATIONS,
    useFactory: () => {
      let translationfiles: string[] = [];
      translationfiles[LocaleHelper.US_LOCALE] = LocaleHelper.US_TRANSLATION_FILE;
      translationfiles[LocaleHelper.DE_LOCALE] = LocaleHelper.DE_TRANSLATION_FILE;
      translationfiles[LocaleHelper.FR_LOCALE] = LocaleHelper.FR_TRANSLATION_FILE;
      if (!(!!localStorage.getItem('currentLocale')) || (localStorage.getItem('currentLocale') == 'undefined')) {
        localStorage.setItem('currentLocale', LocaleHelper.US_LOCALE);
      }
      return translationfiles[localStorage.getItem('currentLocale')];
    }, deps: [LOCALE_ID]
  },
  { provide: TRANSLATIONS_FORMAT, useValue: "xlf" },
    AzureService, MsalService, BroadcastService,
  {
    provide: APP_INITIALIZER, useFactory: appInitializerFn,
    deps: [AzureService], multi: true
  },
  { provide: MSAL_CONFIG, useFactory: msalConfigFactory, deps: [AzureService] },
  { provide: MSAL_CONFIG_ANGULAR, useValue: {} }
    // { provide: LOCALE_ID, useValue: "fr"},
  ],
  entryComponents: [QrCodeModal, RdpLaunchDialogComponent]

})
export class AppModule { }
