
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class RpcService {
    private _url: string = "/HttpRemapProxy/HttpRemapProxy"
    constructor(private _http: HttpClient) { }

    postRequest(requestParams) : any {
        const headers = new HttpHeaders({
            'Accept': 'application/x-www-form-urlencoded',
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        var request = "request=" + JSON.stringify(requestParams);

        return this._http.post(this._url, request, { headers: headers }).pipe(
            catchError(this._errorHandler));
    }

    _errorHandler(error) {
        console.error(error);
        return Observable.throw(error || "Server Error");
    }
}