import { Component, HostListener } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RpcService } from '../rpc.service';
import { AzureService } from '../azure.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  RequestAuthenticateUser, ReplyAuthenticateUser, FieldNameValue
} from '../login/authMessages';
import { BroadcastService, MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './redirecting.component.html',
  styleUrls: ['./redirecting.component.css'],
  providers: []
})

export class RedirectComponent {
  showLoading: boolean;
  showErr: true;
  errorMsg: string;
  token: string;
  requestAuthenticateUser: RequestAuthenticateUser;
  replyAuthenticateUser: boolean;
  replyAuthenticateUserRes: ReplyAuthenticateUser;

  constructor(private service: RpcService, private azureService: AzureService,
    private router: Router, private authService: MsalService, private route: ActivatedRoute) {
    this.requestAuthenticateUser = new RequestAuthenticateUser();
  }

  ngOnInit() {

    /* if(localStorage.getItem('clientId')!=null){
         console.log("redirection initializing");
         this.adalService.init(this.azureService.getAdalConfig());
         this.adalService.handleWindowCallback();
         this.adalService.getUser(); 
         this.azureService.setToken(); 
     } */

    this.showLoading = true;

    let list = new Array<FieldNameValue>();

    let fieldVal = new FieldNameValue();
    fieldVal.name = "username";
    fieldVal.value = this.authService.getAccount().userName;
    list.push(fieldVal)

    this.requestAuthenticateUser.data.user_fields = list;
    this.requestAuthenticateUser.data.os = this.getOSName();
    this.requestAuthenticateUser.data.browser = this.getBrowserName();
    this.requestAuthenticateUser.data.stage = 1;
    this.requestAuthenticateUser.data.thirdPartyToken = String(this.authService.getAccount().idToken);
    this.service.postRequest(this.requestAuthenticateUser)
      .subscribe(
        response => {
          this.replyAuthenticateUserRes = response
          this.showLoading = false;
          if (this.replyAuthenticateUserRes.data.authenticated) {
            localStorage.setItem('userToken', this.replyAuthenticateUserRes.data.userToken);
            if (this.replyAuthenticateUserRes.data.nextStage === -1 || this.replyAuthenticateUserRes.data.nextPrompts.length === 0) {
              this.showLoading = false;
              setTimeout(() => {
                this.router.navigate(['/workspace']);
              }, 2000)
            }
          }
        },
        responseError => {
          this.showLoading = false;
          this.showErr = true;
          this.errorMsg = responseError
        });
  }

  getOSName(): string {

    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;

    var os = 'unknown';
    var clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Linux', r: /(Linux|X11)/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];
    for (var id in clientStrings) {
      var cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    var osVersion = 'unknown';

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        let versions: Array<string>;
        versions = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = versions[1] + '.' + versions[2];
        break;
    }
    return os + ' ' + osVersion;
  }

  getBrowserName(): string {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after "OPR/" 
    if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    return browserName;
  }


}