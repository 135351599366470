import { Injectable } from '@angular/core';
import { RpcService } from './rpc.service';
import { RequestGetAuthenticationStage, ReplyGetAuthenticationStage } from './login/authMessages';
import { MsalModule, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalAngularConfiguration, MsalInterceptor, BroadcastService } from '@azure/msal-angular';
import { Configuration } from 'msal';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { map, take } from 'rxjs/operators';

let appConfig: any;

export function configurator() {
  return appConfig;
}



// Azure specific configuration 
@Injectable()
export class AzureService {
  private adalConfig: string;
  tenant: string;
  clientId: string;
  token: string;
  stageNumber: number;

  countdownSeconds: number = 5;

  requestGetAuthenticationStage: RequestGetAuthenticationStage;
  replyGetAuthenticationStage: ReplyGetAuthenticationStage;

  constructor(private rpcService: RpcService) {
    this.requestGetAuthenticationStage = new RequestGetAuthenticationStage();
  }

  public loadAppConfig(): Promise<any> {
    if (window.location.hash != '#/masteradminlogin') {
      this.stageNumber = 1;
      this.requestGetAuthenticationStage.data.stage = this.stageNumber;
      const promise = this.rpcService.postRequest(this.requestGetAuthenticationStage)
      .toPromise()
      .then(resGetAuthenticationStage => {
        this.replyGetAuthenticationStage = resGetAuthenticationStage
        this.replyGetAuthenticationStage.data.prompts.forEach(prompt => {
          if ((prompt.auth_prompt_name == "redirectURI")) {
            this.setAdalConfig(this.replyGetAuthenticationStage.data.instanceFields);
            appConfig = this.getAdalConfig();
            return appConfig;
          }
        });
      });
      return promise;
      /**const promise = this.rpcService.postRequest(this.requestGetAuthenticationStage)
        .toPromise()
        .then(resGetAuthenticationStage => {
          this.replyGetAuthenticationStage = resGetAuthenticationStage
          this.replyGetAuthenticationStage.data.prompts.forEach(prompt => {
            if ((prompt.auth_prompt_name == "redirectURI")) {
              interval(1000).pipe(take(this.countdownSeconds), map(x => {
                this.countdownSeconds -= 1
                this.setAdalConfig(this.replyGetAuthenticationStage.data.instanceFields);
                appConfig = this.getAdalConfig();
                return appConfig;
              })).subscribe()
            }
          });
        });
      return promise;**/
    } else {
      this.resetAdalConfig();
    }
  }
  public getAdalConfig(): Configuration {
    return {
      auth: {
        // tenant: localStorage.getItem('tenant'),
        clientId: localStorage.getItem('clientId'),
        redirectUri: window.location.origin + '/#/redirecting/',
        postLogoutRedirectUri: window.location.origin + '/',
        validateAuthority: true,
        navigateToLoginRequestUrl: true,
        authority: "https://login.microsoftonline.com/" + localStorage.getItem('tenant') + "/",
      }
    };
  }

  public setAdalConfig(map: Map<string, string>): any {
    for (const key in map) {
      if (key === "clientId") {
        // this.clientId = map[key];
        localStorage.setItem('clientId', map[key]);
      } else if (key === "tenant") {
        // this.tenant = map[key];
        localStorage.setItem('tenant', map[key]);
      }
    }
  }

  public resetAdalConfig() {
    localStorage.removeItem('clientId');
    localStorage.removeItem('tenant');
  }


  public getClientID(): string {
    return localStorage.getItem('clientId')
  }



  public getAuthority() {
    return "https://login.microsoftonline.com/" + localStorage.getItem('tenant') + "/";
  }

  /* public setToken(): void {
         this.adalService.acquireToken(localStorage.getItem('tenant')).subscribe((resToken: string) =>{
             this.token = resToken;
        })
   }
   public getToken() {
       return this.token;
   }
    
   public getUserName(){
       return this.adalService.userInfo.userName;
   }*/

}
