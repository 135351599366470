import { Component } from '@angular/core';
import { AzureService } from './azure.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  ngOnInit(): void {

  }
  constructor(
    private authService: MsalService, private azureService: AzureService, private router: Router
  ) {

    if (localStorage.getItem('clientId') != null) {

      if (this.authService.getAccount()) {
          this.router.navigateByUrl('/redirecting');
      }
    }
  }
}