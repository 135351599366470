import { Component,HostListener } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RpcService } from '../rpc.service';
import {AzureService} from '../azure.service';
import { Router, ActivatedRoute } from '@angular/router';
import { version } from 'punycode';
import {
    RequestAuthenticateUser, ReplyAuthenticateUser, FieldNameValue
  } from '../login/authMessages';
  import { BroadcastService, MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './azurelogintype.component.html',
  styleUrls: ['./azurelogintype.component.css'],
  providers :[]
  
})
export class AzureLoginTypeComponent{


  constructor(private router: Router,private authService: MsalService) {
   
  }

  loginAsAdminUser(){
    this.router.navigate(['/masteradminlogin']);
  }

  loginAsAzureUser(){
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

}