import { Component,HostListener } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
   RequestLogout, ReplyLogout,
} from './authMessages';
import { RpcService } from '../rpc.service';

@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers :[]
  
})
export class LoginComponent{
  currentLocale : string;

  constructor(private rpcService: RpcService) {
    
  }


  ngOnInit() {
    this.currentLocale = localStorage.getItem('currentLocale');
    if (!!localStorage.getItem('userToken')) {
      localStorage.setItem('userToken', null);
    }
  }
}