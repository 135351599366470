import { Injectable } from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {RpcService} from './rpc.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import {RequestFirstTimeAccess, ReplyFirstTimeAccess} from './firsttimeaccess/firsttimeaccess.messages';
import { iif } from 'rxjs';
@Injectable()
export class AuthGuardService implements CanActivate {
  
  requestFirstTimeAccess: RequestFirstTimeAccess;
  replyFirstTimeAccess: ReplyFirstTimeAccess;

  errorMsg: string;

  accessValue: boolean;

  counter : string;
  constructor(
    private router: Router,private rpcService: RpcService,
    private authService: MsalService
  ) {}

   isMobile() : string{
   if(navigator.userAgent.match(/Android/i)){
     if(navigator.userAgent.match(/mobile/i)){
      return "mobile";
     }else {
      return "tablet";
     }  
   }else if(navigator.userAgent.match(/iPhone/i)){
      return "mobile";
   }else if(navigator.userAgent.match(/iPad/i)){
      return "tablet";
   }else {
     return null;
   }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
    ): boolean {
    this.requestFirstTimeAccess = new RequestFirstTimeAccess();
    
    this.rpcService.postRequest(this.requestFirstTimeAccess)
      .subscribe(
      resData => {
        this.replyFirstTimeAccess = resData
        this.accessValue = this.replyFirstTimeAccess.data.accessValue;
        
        this.counter = localStorage.getItem("counter");
       
       /* if(!!this.isMobile()){
          if (this.accessValue && this.counter == '1') {
            this.router.navigate(['login']);
            return true;
          } else if(this.counter == '1'){
            this.router.navigate(['eula']);
            return false;
          }else {
            let type = this.isMobile();
            localStorage.setItem("counter", "1");
            this.router.navigate([type]);
          }
        }else {*/
          if(this.authService.getAccount()){
            this.router.navigate(['redirect']);
            return false; 
          }
          else if (this.accessValue) {
            this.router.navigate(['login']);
            return true;
          } else {
            this.router.navigate(['eula']);
            return false;
        }
      //}
      },
      responseError => {
        this.errorMsg = responseError
      });

    return this.accessValue;
  }

}